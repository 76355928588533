import React, { useEffect, useState } from "react";
import axios from "axios";

function Map() {
  const [contactData, setContactData] = useState({});

  //get data
  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppycontactuspagesection"
      );
      console.log(response.data);
      setContactData(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getdata();
  }, []);
  return (
    <>
      <section className="contact-info-one">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-pin"></i>
                </div>
                <div className="contact-info-one__content">
                  <p
                    className="contact-info-one__text"
                    style={{ marginTop: "-25px" }}
                  >
                    Have any question ?{" "}
                  </p>
                </div>
                <strong
                  style={{
                    marginLeft: "80px",
                    marginTop: "-35px",
                    color: "black",
                  }}
                >
                  {contactData.input6}
                  {contactData.input7}{" "}
                </strong>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-pin"></i>
                </div>
                <div className="contact-info-one__content">
                  <p
                    className="contact-info-one__text"
                    style={{ marginTop: "-25px" }}
                  >
                    Registered Office:
                  </p>
                </div>
                <strong
                  style={{
                    marginLeft: "80px",
                    marginTop: "-35px",
                    color: "black",
                  }}
                >
                  No.22, 1st Floor, South Bazaar, Pattabiram, Chennai - 600072.
                  Landmark: Apple Pharmacy Upstairs
                </strong>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-pin"></i>
                </div>
                <div className="contact-info-one__content">
                  <p
                    className="contact-info-one__text"
                    style={{ marginTop: "-25px" }}
                  >
                    Branch Office:
                  </p>
                </div>
                <strong
                  style={{
                    marginLeft: "80px",
                    marginTop: "-35px",
                    color: "black",
                  }}
                >
                  No 2/5, Rajarathinam Street, West Tambaram, Chennai - 600045.
                  Landmark: Near AG Hospital{" "}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Map;
